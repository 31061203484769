// Main Font Family
$primaryFont: 'Alexandria';

// Main colors
$primaryColor: #000;
$whiteColor: #fff;
$secondaryColor: #B5C700;
$greyColor: #E6E6E6;
$blueColor: #1A40C5;
$lightBlueColor: #24C8CA;
$greenColor: #24CA64;
$pinkColor: #F72D68;
$purpleColor: #47237E;
$yellowColor: #FFD446;
$lightGreenColor: #50E696;
$blueBlackColor: #180D5B;


// Font Weights
$fontWeightBlack: 900;
$fontWeightLight: 300;
$fontWeightExtraBold: 800;
$fontWeightMedium: 500;
$fontWeightBold: bold;
$fontWeightThin: 100;