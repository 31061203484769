@keyframes view { 
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes heartBeat {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(1.1);
  }
  40% {
    -moz-transform: scale(1);
  }
  60% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes heartBeat {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(1.1);
  }
  40% {
    -o-transform: scale(1);
  }
  60% {
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}