@import './Variables.scss';
@import './LoweNext.scss';
@import './samsoung.scss';
@import './Alexandria.scss';

html {
    font-size: 10px;
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: $primaryFont!important;
    background: #fff;
    a {
        color: #000;
        text-decoration: none;
        transition: all .3s ease-in-out;
    }
}

.w-full { width: 100%;}

input ,
label ,
textarea {
    font-family: $primaryFont !important;
}
ul {
    padding: 0;
    &.list-inline {
        display: flex;
        list-style: none;
    }
    &.list-unstyled {
        list-style: none;
    }
}

.hidden-p { opacity: 0; }

.img-fluid {
    width: 100%;
}

.p-0 {
    padding: 0!important;
}

.MuiInputBase-root,
.MuiMenuItem-root,
.MuiTableCell-root {
    font-family: $primaryFont !important;

}

.MuiSelect-filled.MuiSelect-filled {
    background: transparent!important;
}

a.btn-request {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 2rem 2rem;
    font-size: 1.5rem;
    min-width: 17rem;
    color: #fff;
    width: 100%;
    border: 0;
    border-radius: 0;
    min-height: inherit;
    cursor: pointer;
    &:hover {
        opacity: .7;
    }

    @media (max-width:767px) {
        font-size: 10px;
    }
}
button {
    border: none;
    background: none;
    &.btn {
        border-radius: 2rem;
        border: 1px solid #707070;
        padding: 1rem 1.5rem;
        font-family: $primaryFont;
        color: #707070;
        transition: all 0.5s ease-in-out;
    }
    &:hover {
        // background: $secondaryColor;
        // border-color: $secondaryColor;
        color: $whiteColor;
    }
    &:focus {
        outline: none !important;
    }
}
.note {
    font-size: 1.5rem;
    color: #F72D68;
    font-weight: $fontWeightMedium;
}

.direct__chat {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4rem;
    p {
        font-size: 2.5rem;
        color: $primaryColor;
        padding-right: 3rem;
        span {
            text-decoration: underline;
            color: #FE4040;
        }
    }
}

.slick-slide {
    direction: rtl;
    > div > div {
        outline: none !important;
        &:focus {
            outline: none !important;
        }
    }
}
.slick-dots {
    position: absolute;
    li {
        width: auto;
        height: auto;
        margin: 0.3rem;
        &.slick-active {
            button {
                background: $secondaryColor;
            }
        }
        button {
            background: $primaryColor;
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 50%;
            padding: 0;
            margin: 0;
            &:before {
                display: none;
            }
        }
    }
}
/* Page Wrapper */
// .load-container {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100vh;
//     overflow: hidden;
//     z-index: 10;
//     pointer-events: none;
// }
// .load-screen {
//     position: relative;
//     padding-top: 0px;
//     padding-left: 0px;
//     padding-right: 0px;
//     background-color: #000;
//     width: 0%;
//     height: 100%;
// }
// .Headd {
//     opacity: 0;
//     pointer-events: none;
// }
.more__link {
    span {
        overflow: hidden;
    }
}

::-moz-selection {
    background: rgba(181, 199, 0, 1);
    color: #fff;
}
::selection {
    background: rgba(181, 199, 0, 1);
    color: #fff;
}

.breadcrumb {
    text-align: right!important;

    .MuiGrid-item {
        display: flex;
        align-items: center;
        h3 {
            font-size: 36px;
            margin-bottom: 2rem;
            @media (max-width: 1400px) {
                font-size: 32px;
            }

            @media (max-width: 1200px) {
                font-size: 28px;
            }

            @media (max-width: 992px) {
                font-size: 24px;
            }

            @media (max-width: 768px) {
                font-size: 20px;
            }

            @media (max-width: 576px) {
                font-size: 18px;
            }
        }

        p{
            font-size: 18px;
            font-weight: 400;
            @media (max-width: 1400px) {
                font-size: 16px;
            }

            @media (max-width: 1200px) {
                font-size: 15px;
            }

            @media (max-width: 992px) {
                font-size: 14px;
            }

            @media (max-width: 768px) {
                font-size: 13px;
            }

            @media (max-width: 576px) {
                font-size: 12px;
            }
        }
    }

    @media (max-width:599px) {
        min-height: auto!important;
        padding-bottom: 5rem;
    }
}

@media (min-width:601px) and (max-width:768px) {
    html {
        font-size: 5px;
    }
}
// @media (min-width:1367px) and (max-width:1920px) {
//     html {
//         font-size: 14px;
//     }
// }

.templates_table {
    .heading {
        background: #c6c6c6!important;
        span {
            position: absolute;
            z-index: 9;
            padding-left: 2rem;
        }
    }
}

.makeStyles-headingTitle-43 {
    font-size: 4.5rem!important
}
.MuiMenuItem-root {
    font-size: 1.5rem!important;
    min-height: 4rem!important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        /* Styles */
        html {
            font-size: 4px;
        }
    }

    /* Smartphones (landscape) ----------- */
    @media only screen and (min-width : 320px) and (max-width : 768px) {
        html {
            font-size: 4px;
        }

        .section__heading {
            span {
                font-size: rem;
                font-weight: $fontWeightMedium !important;
            }
        }
        // div[id$="__section"] {
        //     padding: 0 3rem !important;
        // }
    }

    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 320px) {
        /* Styles */
        html {
            font-size: 4px;
        }
    }

    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
        html {
            font-size: 7px;
        }
    }

    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    /* Styles */
        html {
            font-size: 7px;
        }
    }

    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
        html {
            font-size: 7px;
        }
    }
    /**********
    iPad 3
    **********/
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1024px) and (max-width: 1224px) {
    /* Styles */
        html {
            font-size: 7px;
        }
    }

    @media (min-width: 1920px) and (max-width: 3840px) {
        html {
            font-size: 15px;
        }
    }

    @media (max-width:767px) {
        .MuiMenuItem-root,
        .MuiInputBase-root {
            font-size: 12px!important;
        }

        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(-12px, 3px) scale(0.85)!important
        }

        .MuiFilledInput-input {
            padding: 10px!important;
        }

        .MuiSelect-icon {
            top: 5px!important;
            width: 20px!important;
            height: 20px!important;
         }
}


.hidden-xs-sm {
    display: none;
    margin: auto;
}


@media (min-width: 959px) { /* Medium devices (md) */
    .hidden-xs-sm {
        display: block; /* Or flex, grid, etc., depending on your layout */
    }
}

.top__content {
    position: relative;
    padding: 4rem 0;
    overflow: hidden;
    z-index: 9;
    .summary {
        position: relative;
        padding: 5rem 0 0;
        margin: 0 4rem 0;
        background: #fff;

        .MuiGrid-item:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        // box-shadow: ;
        .summary__image {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            text-align: center;

            @media (max-width: 959px) {
                display: none;
            }
            img {
                position: relative;
                max-height: 500px;
                width: auto !important;

                @media (max-width: 1200px) {
                  max-height: 350px;
                }

                @media (max-width: 992px) {
                  max-height: 300px;
                }

                // @media (max-width: 768px) {
                //   max-height: 250px;
                // }

                // @media (max-width: 576px) {
                //   max-height: 200px;
                // }
              }
        }
        p {
            font-size: 20px;
            font-weight: $fontWeightMedium;
        }
    }
}


.Toastify__toast-body > div:last-child{
    font-family: $primaryFont!important;
    font-size: 11px;
}