@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-ExtraBold.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Medium.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Black.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Bold.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Thin.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Heavy.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Loew Next Arabic';
    src: url('./../fonts/LoewNextArabic-Light.woff2') format('woff2'),
        url('./../fonts/LoewNextArabic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

