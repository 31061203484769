@import './Variables.scss';
// body.vps,
// body.acadimies,
// body.webdesign,
// body.seo,
// body.apps,
// body.full__servers,
// body.technical__support,
// body.marketing,
// body.shared__hosting {

//     .navbar {
//         .right-menu {
//            .nav-item {
//                 .nav-link {
//                     color: #fff;
//                 }
//             }
//         }
//     }

//     .dropdown-menu {
//       background: #fff;
//       -webkit-box-shadow: none;
//       box-shadow: none;

//       a {
//             color: #000;
//             &::after {
//                 background: #B5C700;
//             }
//         }
//     }

//     .other-links {
//         .nav-item {
//             &:last-of-type {
//                 .nav-link {
//                     background: #fff;
//                     color: #B5C700;
//                     &:hover {
//                         background: #B5C700;
//                         color: #fff;
//                     }
//                 }
//             }
//         }
//     }

//     .mobile-menu  {
//         svg {
//             fill: #fff;
//         }
//     }
// }

.navbar {
    display: flex;
    align-items: center;
    gap: 100px;
    width: 100%;
    @media (max-width:1919px) {
        gap: 80px;
    }


    @media (max-width:1400px) {
        gap: 50px;
    }

    @media (max-width:1199px) {
        gap: 30px;
    }
    .right-menu {
        display: flex;
        gap: 40px;

        @media (max-width:1199px) {
            gap: 20px;
        }

       .nav-item {
        position: relative;
        .nav-link {
            font-size: 20px;
            transition: all .3s ease-in-out;
            cursor: pointer;
            &:hover {
                color: #B5C700
            }

            @media (max-width:1700px) {
                font-size: 18px;
            }

            @media (max-width:1199px) {
                font-size: 16px;
            }

        }


        &:hover > .dropdown-wrapper {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
       }
    }

}

.other-links {
    display: flex;
    // background: #fff;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    overflow: hidden;
    margin-right: auto;
    .nav-item {
        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            height: 60px;
            padding: 10px 30px;
            color: #B5C700;
            @media (max-width:1919px) {
                font-size: 17px;
            }

            @media (max-width:1400px) {
                padding: 10px 20px;
            }

            @media (max-width:1199px) {
                height: 50px;
                font-size: 14px;
            }
        }

        &:last-of-type {
            .nav-link {
                -webkit-border-radius: 30px;
                border-radius: 30px;
                background: #000;
                color: #fff;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
                &:hover {
                    background: #B5C700;
                }
            }
        }
    }

    @media (max-width:991px) {
        &.MuiList-root {
            padding: 0!important;
            width: fit-content;
            margin-right: 20px;
            margin-top: 30px!important;
        }

    }

}

.dropdown-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    padding-top: 10px;
    .dropdown-menu {
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 200px;
        white-space: nowrap;
        padding: 25px 10px;
        background: #000;
        -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,.5);
        box-shadow: 0px 0px 20px rgba(0,0,0,.5);
        border-radius: 4px;
        @media(max-width:991px) {
            gap: 0;
        }
        a {
            display: flex;
            padding: 8px 20px;
            font-size: 13px;
            color: #b9b9b9;
            font-weight: 400;
            position: relative;
            &::after {
                content: '';
                height: 1px;
                width: 30%;
                right: 20px;
                bottom: 0;
                position: absolute;
                background: #b9b9b9;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }

            &:hover {
                color: #B5C700;
            }

            &:hover:after {
                width: 70%;
                background: #B5C700;
            }
        }
    }

}