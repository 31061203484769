.notfound {
    main {
        padding: 80px 0 40px 0;
        text-align: center;
        h2 {
            font-size: 8rem;
        }
    
        p.main {
            font-size: 14rem;
        }
    }
}