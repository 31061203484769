@font-face {
    font-family: 'SamsungOneArabic 400';
    src: url('./../fonts/font/SamsungOneArabic-400.woff2') format('woff2'),
        url('./../fonts/font/SamsungOneArabic-400.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SamsungOneArabic 700';
    src: url('./../fonts/font/SamsungOneArabic-700.woff2') format('woff2'),
        url('./../fonts/font/SamsungOneArabic-700.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SamsungOneArabic 400';
    src: url('./../fonts/font/SamsungOneArabic-400.woff2') format('woff2'),
        url('./../fonts/font/SamsungOneArabic-400.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

