@import '../../assets/style/Variables.scss';

.numbers {
    padding: 4rem 0;
    margin-bottom: 4rem;
    .numbers__slider {
        border: 3px solid $secondaryColor;
        box-shadow: 1.5rem 1.5rem 0 $secondaryColor;
        margin-top: 5rem;
        .numbers__item {
            margin: 4rem 0;
            padding: 0 30px;
        
            @media (max-width: 1400px) {
                margin: 3rem 0;
                padding: 0 24px;
            }
        
            @media (max-width: 1200px) {
                margin: 2.5rem 0;
                padding: 0 20px;
            }
        
            @media (max-width: 992px) {
                padding: 0 16px;
            }
        
            @media (max-width: 768px) {
                margin: 2rem 0;
                padding: 0 12px;
            }
        
            @media (max-width: 576px) {
                padding: 0 8px;
            }

            border-left: 1px solid rgba(0,0,0,0.23);
            .numbers__item--number {
                font-size: 40px;
                font-weight: $fontWeightBlack;
                color: $secondaryColor;
                direction: ltr;
                text-align: right;
            
                @media (max-width: 1400px) {
                    font-size: 36px;
                }
            
                @media (max-width: 1200px) {
                    font-size: 32px;
                }
            
                @media (max-width: 992px) {
                    font-size: 28px;
                }
            
                @media (max-width: 768px) {
                    font-size: 24px;
                }
            
                @media (max-width: 576px) {
                    font-size: 20px;
                }
            }
            
            .numbers__item--title {
                font-size: 20px;
                font-weight: $fontWeightExtraBold;
                color: $primaryColor;
                margin: 0 0 0 16px;
                text-align: right;
                @media (max-width: 1400px) {
                    font-size: 18px;
                    margin: 0 0 0 14px;
                }
            
                @media (max-width: 1200px) {
                    font-size: 16px;
                    margin: 0 0 0 12px;
                }
            
                @media (max-width: 992px) {
                    font-size: 14px;
                    margin: 0 0 0 10px;
                }
            
                @media (max-width: 768px) {
                    font-size: 12px;
                    margin: 0 0 0 8px;
                }
            
                @media (max-width: 576px) {
                    font-size: 10px;
                    margin: 0 0 0 6px;
                }
            }            
        }
        .slick-dots {
            bottom: -5rem;
        }
    }

}

@media (min-width:320px) and (max-width:600px) {
    .numbers {
        .MuiGrid-container {
            max-width: 60rem;
            .numbers__slider {
                flex-direction: column;
                .numbers__item {
                    width: 90%;
                    text-align: center;
                    margin: 3rem auto;
                    border-left: none;
                    border-bottom: 1px solid rgba(0,0,0,0.23);
                    padding: 3rem;
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}