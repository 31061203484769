@import 'assets/style/Variables.scss';
@import 'assets/style/KeyFrames.scss';

.home {
    #help__section {
        padding-top: 0!important;

        .help__section--text {
            @media (max-width:991px) {
                margin: 0;
                padding-top: 0;
            }
        }
    }
}

#help__section {
    background: $whiteColor;
    position: relative;
    padding-top: 40px;
    .section__heading {
        h3 {
            margin-bottom: 0;
        }
    }

    @media (max-width: 1400px) {
        padding-top: 36px;
    }

    @media (max-width: 1200px) {
        padding-top: 32px;
    }

    @media (max-width: 992px) {
        padding-top: 28px;
        .help__section--text {
            padding-bottom: 0;
            margin-bottom: 0;
            p { margin: 0;}
        }
    }

    @media (max-width: 768px) {
        padding-top: 24px;
    }

    @media (max-width: 576px) {
        padding-top: 20px;
    }

    > div {
        position: relative;
        z-index: 2;
        .help__section--contact {
            text-align: center;
            @media (max-width:992px) {
                display: flex;
            }
            > div {
                padding: 0 !important;
                margin-bottom: 2rem;
                > div {
                    padding: 0 !important;
                }
            }
            img {
                max-width: 15rem;
                margin: auto;
                width: auto !important;
                height: auto !important;
                position: relative !important;
                animation: 3s heartBeat infinite;
            }
            a {
                direction: ltr;
                font-size: 40px;
                font-weight: $fontWeightMedium;
                color: $primaryColor;
                margin: 2rem 0;
                display: block;
                span {
                    color: $secondaryColor;
                    font-weight: $fontWeightBlack;
                }
            }
            .contact-us {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 10px 100px;
                height: 70px;
                background: #000;
                color: #fff;
                font-size: 18px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                -webkit-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                &:hover {
                    background: #B5C700;
                }
                @media (max-width:992px) {
                    padding: 10px 50px;
                    height: 60px;
                    font-size: 14px
                }

                @media (max-width:768px) {
                    padding: 10px 30px;
                    height: 50px;
                }
            }
        }
    }

    &:after {
        background: url(../../../assets/images/help.png);
        background-repeat: no-repeat;
        background-size: 100%;
        content: "";
        position: absolute;
        width: 40.2rem;
        height: 43.5rem;
        top: -40px;
        right: -40px;
        z-index: 1;
    }
}

@media (min-width:320px) and (max-width:600px) {
    #help__section {
        &:after {
            display: none;
        }
        .help__section--text {
            padding-bottom: 0;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}