@import './Variables.scss';

/*site-nav*/
#sidemenu {
    position: fixed;
    width:1.4%;
    height:100%;
    background: $primaryColor;
    top:0;
    right: 0;
    z-index:999;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
    transition: all 1.2s ease-in-out;
    transition-delay: 1s;
    // white-space: nowrap;
    .inner_menu {
        padding: 80px;
        height: 100vh;
    }
    &.active {
        width:100% !important;
        transition: all 1.2s ease-in-out ;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%);
        .layer {
            opacity: 1;
            transition: all 0.7s ease-in-out;
            transition-delay: 1s;
        }
    }
    .layer {
        height:100%;
        width: 50%;
        z-index:9;
        overflow: hidden;
        display: inline-block;
        flex: 2 2;
        opacity: 0;
        transition: all 0.7s ease-in-out;
        transition-delay: 0.1s;
        h3 {
            font-size: 42px;
            font-weight: $fontWeightBlack;
            color: $whiteColor;
        }
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            div {
                padding: 10px 5px;
                white-space: nowrap;
                width: 50%;
                button {
                    font-weight: $fontWeightBlack;
                    font-size: 42px;
                    color: $whiteColor;
                    font-family: $primaryFont;
                    span {
                        color: $secondaryColor;
                        font-size: 2.4rem;
                        font-weight: $fontWeightMedium;
                    }
                }
            }
        }
        &.left_layer {
            flex: 1;
            ul {
                li{
                    display: block;
                    width: 100%;
                    a {
                        font-size: 2.4rem;
                        font-weight: $fontWeightMedium;
                        position: relative;
                        padding-right: 3.5rem;
                        color: $whiteColor;
                        &:after {
                            // content: "";
                            // position: absolute;
                            // top: 50%;
                            // right: 0;
                            // height: 0.2rem;
                            // width: 2.3rem;
                            // background: $whiteColor;
                        }
                    }
                }
            }
        }
    }
    .close {
        position: absolute;
        left: 100px;
        top: 50px;
        cursor: pointer;
    }
    .contact {
        a {
            color: #B5C700;
            font-size: 3.5rem;
            font-weight: $fontWeightMedium;
            direction: rtl;
            strong {
                font-weight: $fontWeightBlack;
            }
        }
    }
}

