@import './Variables.scss';

header {
    position: relative;
    padding: 40px 0;
    width: 100%;
    top: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #fff!important;
    z-index: 99;

    @media (max-width:991px) {
        padding: 20px 0;
    }

    &.sticky {
        position: fixed;
        box-shadow: 0 20px 20px rgba(0,0,0,0.03);
    }

    .inner-header {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        gap: 200px;

        @media (max-width:1919px) {
            gap: 140px;
        }

        @media (max-width:1700px) {
            gap: 130px;
        }

        @media (max-width:1400px) {
            gap: 60px;
        }

        @media (max-width:991px) {
            justify-content: space-between;
        }

        .logo {
            a {
                display: flex;
                width: 316px;
                font-weight: 700;
                font-size: 1rem;
                text-decoration: none;
                color: $primaryColor;
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
                @media (max-width:1400px) {
                    max-width: 250px;
                }

                @media (max-width:991px) {
                    max-width: 200px;
                }

                @media (max-width:767px) {
                    max-width: 180px;
                }
            }


        }

        .desktop-menu {
            @media (max-width:991px) {
                display: none;
            }
        }
        .mobile-menu {
            display: none;
            @media (max-width:991px) {
                display: block;
            }
            .MuiToolbar-gutters {
                padding: 0;
                .MuiButtonBase-root {
                    padding: 0;
                    margin: 0;
                    svg {
                        width: 40px;
                        height: 40px;
                    }
                    &:hover {
                        color: #B5C700;
                    }
                }
            }



        }
        .close {
            li {
                .menu-icon {
                    color: $whiteColor !important;
                    svg {
                        path {
                            fill:#fff
                        }
                    }
                }
                span.dot {
                    background: $whiteColor;
                }
            }
        }
    }
    .hamburger-menu {
        display: none;
        z-index: 9;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        .menu-secondary-background-color {
            background: $secondaryColor;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
        .menu-layer {
            position: relative;
            background: $primaryColor;
            height: 100%;
            overflow: hidden;
            // clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%);
            .menu-city-background {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                opacity: 0;
                background-size: cover;
                background-repeat: no-repeat;
                animation-name: backgroundEffect;
                animation-duration: 30s;
                animation-iteration-count: infinite;
                &:after {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    right: 0;
                    top: 0;
                    background-color: rgba(0,0,0,0.8);
                    // z-index: 9;
                    content: "";
                }
            }
            .wrapper {
                position: relative;
                .menu-links {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    top: 10rem;
                nav {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 20px;
                    ul {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding-right: 10rem;
                        gap: 20px;
                        li {
                            list-style: none;
                            font-size: 40px;
                            font-weight: 700;
                            cursor: pointer;
                            overflow: hidden;
                            position: relative;
                            width: 32rem;
                            display: inline-block;

                        }
                    }
                    .menu__shape ,
                    .menu__shape:after ,
                    .menu__shape:before {
                        display: block;
                        position: absolute;
                        background-color: #B5C700;
                        width: 0;
                        height: 4px;
                        right: 4%;
                        top: 20px;
                        z-index: 9;
                        animation: logoAnimation 0.7s cubic-bezier(0.17, 0.67, 0.21, 1) 0.5s forwards;
                    }
                    .menu__shape:after {
                        content: "";
                        top: 9px;
                        right: 7px;
                    }
                    .menu__shape:before {
                        content: "";
                        top: -10px;
                        right: -6px;
                    }
                }
                .info {
                    color: #fff;
                    // width: 300px;
                    flex: 1;
                    ul {
                        li{
                            display: block;
                            width: 100%;
                            a {
                                font-size: 2.4rem;
                                font-weight: $fontWeightMedium;
                                position: relative;
                                padding-right: 3.5rem;
                                color: $whiteColor;
                                line-height: 2;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    height: 0.2rem;
                                    width: 2.3rem;
                                    background: $whiteColor;
                                }
                            }
                        }
                    }
                    .contact {
                        a {
                            color: $secondaryColor;
                            font-size: 3.5rem;
                            font-weight: $fontWeightMedium;
                            direction: rtl;
                            strong {
                                font-weight: $fontWeightBlack;
                            }
                        }
                    }
                    h3 {
                        font-size: 42px;
                        font-weight: $fontWeightBlack;
                        color: $whiteColor;
                    }
                }
            }
            .locations {
                position: absolute;
                bottom: -80px;
                color: #fff;
                margin-top: 16px;
                font-size: 0.8rem;
                font-weight: 600;
                span {
                cursor: pointer;
                &:first-child {
                    margin-left: 64px;
                }
                font-weight: 400;
                margin: 0 32px;
                transition: 0.3s ease-in-out;
                &:hover {
                    color: #fff;
                    background: $secondaryColor;
                    padding: 8px 24px;
                    border-radius: 4px;
                }
                }
            }
        }
      }
    }
}

.MuiDrawer-paper {
    clip-path: polygon(23% 0, 100% 0, 100% 100%, 0% 100%);

    .MuiList-root.main-links  {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        padding-left: 80px;
        .nav-item {
            .nav-link {
                display: block;
                padding: 15px 0;
                font-size: 16px;
                cursor: pointer;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid #b9b9b936;
            }

            & > .dropdown-wrapper  {
                position: static;
                display: none;
                -webkit-transform: scale(1);
                transform: scale(1);
                padding: 0;
                margin-bottom: 15px;

                .dropdown-menu {
                    background: #ffff;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    padding: 0;
                    a {
                        color: #000;
                        &:hover {
                            color: #B5C700;
                        }
                        &::after {
                            display: none;
                        }
                     }
                }

            }

            &:hover > .dropdown-wrapper {
                display: block;
            }
        }
    }
}



@keyframes backgroundEffect {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 40% 10%;
    }
    50% {
      background-position: 0 10%;
    }
    75% {
      background-position: 10% 40%;
    }
    100% {
      background-position: 0% 0%;
    }
}

// @keyframes logoAnimation {
//     0% {
//         width: 0;
//         left: 0
//     }

//     50% {
//         width: 20%;
//         left: 0
//     }

//     100% {
//         left: 0;
//         width: 20%;
//     }
// }

@media only screen and (min-width : 320px) and (max-width : 768px) {
    header {
        .inner-header {
            // flex-direction: column;
            align-items: center;
            .menu {
                // width: 100%;
                ul {
                    justify-content: space-between;
                }
            }
        }
        .hamburger-menu {
            .menu-layer {
                .wrapper {
                    .menu-links {
                        display: block;
                        nav {
                            ul {
                                padding: 0;
                                li {
                                    width: 130px;
                                    height: 40px;
                                    a {
                                        right: 0;
                                    }
                                }
                            }
                        }
                        .info {
                            text-align: right;
                            padding-right: 9rem;
                            padding-top: 3rem;
                            .contact {
                                padding-top: 3rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
