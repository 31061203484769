@import './../../assets/style/Variables.scss';

footer {
    background: $whiteColor;
    padding: 3rem;
    position: relative;
    z-index: 1;
    .footer__title {
        color: $greyColor;
        font-size: 3.7rem;
        font-weight: $fontWeightExtraBold;
        margin-bottom: 3rem;
    }
    .footer__links--help {
        li {
            a {
                color: $primaryColor;
                font-size: 3rem;
                font-weight: $fontWeightExtraBold;
                line-height: 1.9;
            }
        }
    }
    .footer__links {
        margin-bottom: 5rem;

        li {
            width: 25%;
            display: inline-block;
            margin-bottom: 10px;
            a {
                color: $primaryColor;
                font-size: 1.8rem;
                font-weight: $fontWeightMedium;
                line-height: 1.9;
                transition: all 0.5s ease-in-out;
            }
            &:hover {
                a {
                    color: $secondaryColor;
                }
            }
        }
    }
    .rowaad__items {
        display: flex;
        align-items: flex-start;
        gap: 4rem;
        margin-bottom: 8rem;
        .rowaad__item--tech {
            flex: 1;
            text-align: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            text-align: right;
            h2 {
                font-size: 40px;
                font-weight: $fontWeightBlack;
            
                @media (max-width: 1400px) {
                    font-size: 36px;
                }
            
                @media (max-width: 1200px) {
                    font-size: 32px;
                }
            
                @media (max-width: 992px) {
                    font-size: 28px;
                }
            
                @media (max-width: 768px) {
                    font-size: 24px;
                }
            
                @media (max-width: 576px) {
                    font-size: 20px;
                }
                
                span {
                    display: block;
                    color: #B5C700;
                }
            }

        }

        .technologies {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
            .rowaad__tech {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: #F3F3F3;
                cursor: pointer;
                -webkit-transition: all .5s ease-in-out;
                transition: all .5s ease-in-out;
                &:hover {
                    -webkit-transform: scale(1.1);
                    transform: scale(1.1);
                    -webkit-box-shadow: 0 10px 6px rgba(0, 0, 0, .08);
                    box-shadow: 0 10px 6px rgba(0, 0, 0, .08);
                }

                @media (max-width: 1366px) {
                    width: 90px;
                    height: 90px;
                }

                @media (max-width:575px) {
                        width: 75px;
                        height: 75px;
                }

                @media (max-width:391px) {
                    width: 65px;
                    height: 65px;
                }

                img {
                    max-width: 50%;
                }
            }

            @media (max-width:391px) {
                gap: 9px;
            }
        }

    }
    .footer__rights {
        margin-bottom: 30px;
        p {
            font-size: 14px;
            font-weight: $fontWeightLight;
            line-height: 1.8;
            span {
                font-weight: $fontWeightBlack;
            }
        }
    }

    .rowaad__images {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            padding: 10px;
            -webkit-box-shadow: 0 0 6px 6px rgba(0, 0, 0, .02);
            box-shadow: 0 0 6px 6px rgba(0, 0, 0, .02);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            &:hover {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .footer__rowaad {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        p {
            font-size: 20px;
            font-weight: $fontWeightBlack;
            display: flex;
            align-items: center;
            @media (max-width:768px) {
               font-size: 16px;   
               margin: 15px 0 20px;  
            }
            // img {
            //     // width: 4rem;
            //     // height: 4rem;
            //     animation: heart-burst 1.2s steps(28) infinite;
            // }
            .rowaad_heart {
                position: relative;
                top: 0.3rem;
                background-image: url('./../images/web_heart_animation.png');
                background-repeat: no-repeat;
                background-size: 2900%;
                background-position: center;
                height: 6rem;
                width: 6rem;
                animation: heart-burst 1.2s steps(28) infinite;
                margin: 0 auto;
                display: inline-block;
              }

              
        }
    }
}

.social__links {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width:1200px) {
        gap: 20px
    }
    a {
        display: flex;
        align-items: center;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        img {
            height: 2.2rem;
            @media (max-width: 1200px) {
                height: 3.6rem,
            }
            @media (max-width: 992px) {
                height: 3.6rem,
            }
            @media (max-width: 768px) {
                height: 6.2rem,
            }
        }
        &:hover {
            img {
                opacity: .5;
                -webkit-transition: all .5s ease-in-out;
                transition: all .5s ease-in-out;
            }
        }
    }
}


@media (min-width:320px) and (max-width:768px) {
    footer {
        padding-left: 0;
        padding-right: 0;
        .faq {
            text-align: center;
        }
        .rowaad__images {
            justify-content: center;
        }
        .footer__links {
            li {
                margin-bottom: 8px;
                width: 49%;
                a {
                    font-size: 2.4rem;
                }
            }
        }
        .rowaad__items {
            flex-wrap: wrap;
            .rowaad__item {
                flex-direction: row;
                p {
                    display: block;
                }
            }
        }
        .footer__rights {
            text-align: center;
        }
        .social__links {
            justify-content: center;
        }
        .footer__rowaad {
            justify-content: center;
        }
    }
}

@keyframes heart-burst {
	0% {
		background-position: left
	}
	100% {
		background-position: right
	}
}

.fixed-btns {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 999;
    @media (max-width:575px) {
        right: 15px;
        bottom: 15px;
    }
   > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background: #B5C700;
        color: #fff;
        animation: bounce2 3s ease-in infinite;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 18px 11px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 0px 18px 11px rgba(0, 0, 0, 0.06);
        &:last-of-type {
            background-color: #fff;
            color: #B5C700;
        }
      
        @media (max-width:575px) {
            width: 50px;
            height: 50px;
        }
        
        svg {
            display: block;
            width: 40px;
            height: 40px;
            -webkit-animation: pulse 3s ease-in-out infinite;
            animation: pulse 3s ease-in-out infinite;
            @media (max-width:575px) {
                width: 30px;
                height: 30px;
            }
        }
   }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
    25% {
        -webkit-transform: scale(1.1) rotate(-15deg);
        transform: scale(1.1) rotate(-15deg);
    }
    75% {
        -webkit-transform: scale(1.1) rotate(5deg);
        transform: scale(1.1) rotate(5deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1) rotate(0deg);
        -moz-transform: scale(1) rotate(0deg);
        -ms-transform: scale(1) rotate(0deg);
        -o-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
    25% {
        -webkit-transform: scale(1.1) rotate(-15deg);
        -moz-transform: scale(1.1) rotate(-15deg);
        -ms-transform: scale(1.1) rotate(-15deg);
        -o-transform: scale(1.1) rotate(-15deg);
        transform: scale(1.1) rotate(-15deg);
    }
    75% {
        -webkit-transform: scale(1.1) rotate(5deg);
        -moz-transform: scale(1.1) rotate(5deg);
        -ms-transform: scale(1.1) rotate(5deg);
        -o-transform: scale(1.1) rotate(5deg);
        transform: scale(1.1) rotate(5deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0deg);
        -moz-transform: scale(1) rotate(0deg);
        -ms-transform: scale(1) rotate(0deg);
        -o-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }
}